<template>
  <card-component title="Login" icon="lock" :has-card-header-background="true" :has-button-slot="true">
    <form @submit.prevent="submit" method="POST">
      <b-field>
        <b-input icon="email" type="email" v-model="form.email" placeholder="Username" name="email" required />
      </b-field>

      <b-field label="Contraseña">
        <b-input v-model="form.password" type="password" name="password" required/>
      </b-field>

      <hr>

      <b-field grouped>
        <div class="control">
          <button type="submit" class="button is-black" :class="{'is-loading':isLoading}">
            Ingresar
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'

export default {
  name: 'Login',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      form: {
        email: '',
        password: ''
      }
    }
  },
  created () {
    this.$store.commit('removeToken')
  },
  methods: {
    submit () {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.$store.dispatch('obtainToken',
          {
            username: this.form.email,
            password: this.form.password
          }
        ).then(result => {
          this.$router.push('/')
        }).catch((e) => {
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      }, 750)
    }
  }
}
</script>
<style scoped>
  .place-for-login {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .link-for-reset {
    font-size: 0.8em;
  }
</style>
